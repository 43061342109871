import React from "react";
import { Link } from "gatsby";
import { getImageUrl } from "../../api/Api";
import { translate } from "../../helpers/I18n";
import { Cell, Grid, Row } from "../FoundationGrid";
import Icon from "../Icon";
import Flip from "react-reveal/Flip";
import RevealFade from "react-reveal/Fade";
import Pricing from "../Pricing";

function MainPageSections({ page, sections }) {
  if (
    !sections &&
    (!page.parts ||
      !page.parts.mainPageSections ||
      page.parts.mainPageSections.length === 0)
  )
    return null;

  if (!sections) sections = page.parts.mainPageSections;

  return (
    <div className="mainpage-sections">
      {sections.map((section, index) => {
        return (
          <div className="mainpage-section" key={index}>
            <Grid>
              <Row>
                <Cell md={24}>
                  <RevealFade>
                    {section.PreTitle ? (
                      <div className="mainpage-section-pretitle">
                        {section.PreTitle}
                      </div>
                    ) : (
                      ""
                    )}
                    <h2>
                      {section.Title}
                      {/*<span className="title-number">{index + 1}</span>
                      <span className="title-text">{section.Title}</span>*/}
                    </h2>
                    <div
                      className="introtext"
                      dangerouslySetInnerHTML={{
                        __html: section.IntroText,
                      }}
                    />
                  </RevealFade>
                </Cell>
              </Row>
              <Row>
                <Cell md={14} mdo={5}>
                  <RevealFade>
                    <div
                      className="mainpage-section-content"
                      dangerouslySetInnerHTML={{
                        __html: section.Content,
                      }}
                    />
                  </RevealFade>
                </Cell>
              </Row>
            </Grid>
            {section.PartsPricing && section.PartsPricing.length > 0 ? (
              <Pricing page={page} prices={section.PartsPricing} />
            ) : null}
            {section.FooterContent && (
              <Grid>
                <Row>
                  <Cell md={14} mdo={5}>
                    <RevealFade>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: section.FooterContent,
                        }}
                      />
                    </RevealFade>
                  </Cell>
                </Row>
              </Grid>
            )}
          </div>
        );
      })}
    </div>
  );
}

export default MainPageSections;
