import React from "react";
import { Cell, Grid, Row } from "../FoundationGrid";

function Pricing({ page, prices }) {
  let md = 8;
  let mdo = 0;

  switch (prices.length) {
    case 1:
      mdo = 8;
      break;
    case 2:
      mdo = 4;
      break;
    case 3:
      mdo = 0;
      break;
    case 4:
      md = 6;
      mdo = 0;
      break;
  }

  return (
    <div className="__pricing-plans">
      <Grid>
        <Row margin="xy">
          {prices.map((price, index) => {
            return (
              <Cell sm={24} md={md} mdo={index === 0 ? mdo : 0} key={index}>
                <Price price={price} />
              </Cell>
            );
          })}
        </Row>
      </Grid>
    </div>
  );
}

const Price = ({ price }) => {
  return (
    <div className="__pricing-plan center-vertically">
      <h3>{price.Name}</h3>
      {price.Box1Content && (
        <div className="__pricing-plan-box">
          <div dangerouslySetInnerHTML={{ __html: price.Box1Content }} />
        </div>
      )}
      {price.Box2Content && (
        <div className="__pricing-plan-box">
          <div dangerouslySetInnerHTML={{ __html: price.Box2Content }} />
        </div>
      )}
      {price.Box3Content && (
        <div className="__pricing-plan-box">
          <div dangerouslySetInnerHTML={{ __html: price.Box3Content }} />
        </div>
      )}

      <div className="__pricing-plan-price">
        {price.OldPrice && (
          <>
            <div className="__pricing-plan-price-oldprice">
              {price.OldPrice}
            </div>
          </>
        )}
        <div className="__pricing-plan-price-price">{price.Price}</div>
      </div>
    </div>
  );
};

export default Pricing;
