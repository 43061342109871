import React, { useEffect, useState } from "react";
import MainLayout from "../../layout/MainLayout";
import "react-slideshow-image/dist/styles.css";
import { initPage } from "../../api/Api";
import Loading from "../Loading";
import { Cell, Grid, Row } from "../FoundationGrid";
import MainPageHeader from "../mainpage/MainPageHeader";
import MainPageSections from "../mainpage/MainPageSections";
import CallToAction from "../CallToAction";
import RevealFade from "react-reveal/Fade";

const identifier = "about";

function AboutPage({ locale }) {
  const [page, setPage] = useState({
    identifier: identifier,
    nav: "about",
    locale: locale,
    parts: { mainPageSections: [], mainPageSpecialSections: [] },
    ctas: { about: null },
  });

  const init = () => {
    initPage(page, (page) => setPage({ ...page }));
  };

  useEffect(init, []);

  if (!page.data) return <Loading page={page} fullscreen />;

  return (
    <div className={`page main-page page-${page.identifier}`}>
      <MainLayout page={page}>
        <Grid>
          <Row>
            <Cell sm={24}>
              <MainPageHeader page={page} noFullHeight />
            </Cell>
          </Row>
        </Grid>
        <MainPageSections page={page} />
        <RevealFade bottom>
          <Grid>
            <Row>
              <Cell sm={24}>
                <CallToAction type="box" cta={page.ctas.about} />
              </Cell>
            </Row>
          </Grid>
        </RevealFade>
      </MainLayout>
    </div>
  );
}

export default AboutPage;
